import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    currentUser: null,
    company: null,
    companyUserCount: 0,
    dateFormats: { dateFns: "dd/MM/yyyy"},
    isDeleted: false,
    searchText: ""
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setCompany(state, company) {
      state.company = company;
    },
    setCompanyUserCount(state, count) {
      state.companyUserCount = count;
    },
    incrementCompanyUserCount(state) {
      state.companyUserCount += 1;
    },
    decreaseCompanyUserCount(state) {
      state.companyUserCount -= 1;
    },
    logout(state) {
      state.currentUser = null;
    },
    setCompanyUsersIsDeleted(state, isDeleted) {
      state.isDeleted = isDeleted;
    },
  },
  actions: {
    setCurrentUser({ commit }, user) {
      commit("setCurrentUser", user);
    }
  },
  modules: {}
});
