<template lang="pug">
  #root
    nav.fixed.top-0.left-0.right-0.z-50(v-if="currentUser")
      .grid.grid-cols-4.px-8.h-16.bg-grey-90
        .col-span-2.h-full
          img.h-16.p-4.inline-block(src="@/assets/images/logo.png")
          router-link.menu-item.text-white(v-for="(link, name) in links", :key="link.to", :to="link.to", class="no-underline") {{ link.label }}

        .col-span-2.h-full.flex.flex-row.items-center
          #search.ui.search.flex-1.h-10.mr-4(v-click-outside="hideSearch")
            app-icon.absolute.transform.mt-5.ml-4( icon="search", class="-translate-y-1/2", stroke="var(--grey-50)" )
            input.bg-grey-80.border.h-10.border.border-1.border-grey-70.mr-4.w-full.pl-12.pr-4.text-grey-60(class="focus:bg-white", type='text', placeholder='Search for something...', @keyup="searchQuery", @focus="isFocused = true")
            .bg-white.shadow-lg.relative.z-50(v-if="isFocused")
              .p-4.cursor-pointer.flex.flex-row(v-for="(result, idx) in search", :key="idx", class="hover:bg-grey-20", @click="clickedSearchResult(result)")
                .flex-1 {{ searchResultText(result) }}
                .flex-initial {{ result.__typename }}

          .flex-initial.ml-4
            app-user-menu


      .bg-grey-10.shadow-lg
        .grid.grid-cols-4.h-12.pl-64.pr-64
          .col-span-3
            app-header.leading-16(margin="") {{ pageTitle }}

        .grid.grid-cols-4.h-12.pl-64.pr-64(v-if="submenu")
          .col-span-4
            router-link.submenu-item.text-grey-80(v-for="link in submenu", class="no-underline", :key="link.to", :to="{ name: link.to, params: { id: $route.params.id } }") {{ link.label }}

    .grid.grid-cols-1.pl-64.pr-64.min-h-full.bg-grey-20.transition-spacing.duration-200.relative.z-40(:class="contentClasses")
      .col-span-1
        route-transition
          router-view

    flash-message-container
    portal-target(name="dropdowns")
</template>

<script>
import AppUserMenu from "@/components/elements/AppUserMenu.vue";
import RouteTransition from "@/components/RouteTransition.vue";
import { mapState } from "vuex";
import SearchQuery from "@/graphql/queries/Search.gql";

export default {
  apollo: {
    search: {
      query: SearchQuery,
      skip() {
        return this.searchTerm === "";
      },
      variables() {
        return { term: this.searchTerm };
      },
    },
  },
  components: {
    AppUserMenu,
    RouteTransition,
  },
  data() {
    return {
      isFocused: false,
      search: [],
      searchTerm: "",
      links: {
        Companies: {
          to: "/companies",
          label: "Companies",
        },
        users: {
          to: "/users",
          label: "Superadmin Users",
        },
      },
    };
  },
  methods: {
    searchQuery(evt) {
      this.searchTerm = evt.target.value;
    },
    clickedSearchResult(result) {
      this.hideSearch();
      if (result.__typename === "User") {
        var userCompany = result.company.id;
        this.$router.push(`/companies/${userCompany}/users`);
      } else {
        var company = result.id;
        this.$router.push(`/companies/${company}/details`);
      }
    },
    hideSearch() {
      this.isFocused = false;
    },
    searchResultText(result) {
      if (result.__typename === "Company") {
        return result.name;
      } else {
        return `${result.name} (${result.company.name})`;
      }
    },
  },
  computed: {
    contentClasses() {
      if (this.submenu) {
        return "pt-56";
      }

      return "pt-40";
    },
    submenu() {
      if (!this.submenuRoute) {
        return null;
      }

      return this.submenus[this.submenuRoute.name];
    },
    submenus() {
      return {
        "View company": [
          {
            to: "Details",
            label: "Details",
          },
          {
            to: "Subscription",
            label: "Subscription",
          },
          {
            to: "Users",
            label: `Users (${this.$store.state.companyUserCount})`,
          },
          {
            to: "Activity",
            label: "Activity",
          },
        ],
      };
    },
    submenuRoute() {
      return this.$route.matched.find(record => record.meta.submenu);
    },
    ...mapState(["currentUser"]),
    pageTitle() {
      if (this.$store.state.company) {
        return this.$store.state.company.name;
      } else {
        return this.$route.name;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.menu-item,
.submenu-item {
  @apply inline-block;
  @apply ml-16;
  line-height: 3rem;
  @apply h-16;
  height: 3rem;

  &:first-child {
    @apply ml-0;
  }
}

.submenu-item {
  &:active,
  &.router-link-exact-active {
    @apply font-bold;
    @apply text-tribal-aqua;
    @apply border-b-4;
    @apply border-tribal-aqua;
  }
}
</style>
