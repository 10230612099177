import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import meQuery from "@/graphql/queries/Me.gql";
import { apolloClient } from "@/vue-apollo";

const SignIn = () => import("@/views/sign_in/SignIn.vue");

const CompaniesContainer = () => import("@/views/Companies.vue");
const Companies = () => import("@/views/companies/Index.vue");
const ShowCompany = () => import("@/views/companies/Show.vue");
const CompanyDetails = () => import("@/views/companies/show/Details.vue");
const CompanySubscription = () =>
  import("@/views/companies/show/Subscription.vue");
const CompanyUsers = () => import("@/views/companies/show/users/Index.vue");
const NewCompanyUser = () => import("@/views/companies/show/users/New.vue");
const UpdateCompanyUser = () => import("@/views/companies/show/users/Update.vue");
const CompanyActivity = () => import("@/views/companies/show/Activity.vue");
const NewCompany = () => import("@/views/companies/New.vue");

const SuperadminUsersContainer = () => import("@/views/SuperadminUsers.vue");
const SuperadminUsers = () => import("@/views/superadmin_users/Index.vue");
const NewSuperadminUser = () => import("@/views/superadmin_users/New.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/companies",
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/companies",
    component: CompaniesContainer,
    children: [
      {
        path: "",
        name: "Companies",
        component: Companies,
      },
      {
        path: "new",
        name: "Add company",
        component: NewCompany,
      },
      {
        path: ":id",
        name: "View company",
        redirect: ({ params }) => {
          return { name: "Details", id: params.id };
        },
        component: ShowCompany,
        meta: {
          submenu: true,
        },
        children: [
          {
            path: "details",
            name: "Details",
            component: CompanyDetails,
          },
          {
            path: "subscription",
            name: "Subscription",
            component: CompanySubscription,
          },
          {
            path: "users",
            name: "Users",
            component: CompanyUsers,
          },
          {
            path: "users/new",
            name: "Add company user",
            component: NewCompanyUser,
          },
          {
            path: "users/:user_id/update",
            name: "Update company user",
            component: UpdateCompanyUser,
          },
          {
            path: "activity",
            name: "Activity",
            component: CompanyActivity,
          },
        ],
      },
    ],
  },

  {
    path: "/users",
    component: SuperadminUsersContainer,
    children: [
      {
        path: "",
        name: "Superadmins",
        component: SuperadminUsers,
      },
      {
        path: "new",
        name: "Add User",
        component: NewSuperadminUser,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/signin") {
    apolloClient
      .query({
        query: meQuery,
      })
      .then(result => {
        store.commit("setCurrentUser", result.data.me);
        next();
      })
      .catch(() => {
        next({
          path: "/signin",
          query: { redirect: to.fullPath },
        });
      });
  } else {
    next();
  }
});

const DEFAULT_PAGE_TITLE = "ManagePlaces Superadmin"
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_PAGE_TITLE;
  });
});

export default router;
