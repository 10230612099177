<script>
import { Components } from "manageplaces-ui-kit";
import store from "@/store";
import router from "@/router";

const signOut = () => {
  store.commit("logout");
  router.push(`/signin`);
  store.dispatch(
    "flashMessages/success",
    "You have successfully logged out",
    null
  );
};

const getCurrentUser = (h, user) => {
  return [
    h("p", { staticClass: "mr-16" }, "Logged in as"),
    h("strong", { staticClass: "mr-8" }, user.name),
  ];
};

export default {
  functional: true,
  render(h) {
    const user = store.state.currentUser;
    const options = [
      { type: "content", slot: "currentUser" },
      { type: "divider" },
      { label: "Sign out", icon: "padlock", signOut: true },
    ];

    const avatar = h(Components.AppAvatar, {
      staticClass: "cursor-pointer",
      props: { avatar: user.avatar },
    });
    const dropdown = h(Components.AppDropdown, {
      props: { options: options, pointing: true },
      scopedSlots: {
        trigger: () => avatar,
        currentUser: () => getCurrentUser(h, user),
      },
      on: {
        click: item => {
          if (item.signOut) {
            signOut();
          } else {
            window.location.href = item.href;
          }
        },
      },
    });

    return dropdown;
  },
};
</script>
