import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createProvider } from "./vue-apollo";
import UIKit from "manageplaces-ui-kit";
import Vuelidate from "vuelidate";
import vuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";
import ValidationMessages from "@/config/ValidationMessages";

const apolloProvider = createProvider();
const vueOpts = {
  apolloProvider,
  router,
  store,
};

Vue.config.productionTip = false;
Vue.use(UIKit, { store, vueOpts });

Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor, {
  messages: ValidationMessages,
});
Vue.component("FormWrapper", templates.FormWrapper);

/**
 * GLOBAL STYLING
 *
 * Whilst we don't want any proper global styles,
 * there are a few styles that make sense such as
 * anchor tags. This will also setup styles from
 * tailwind
 */
import "manageplaces-ui-kit/dist/UIKit.css";
import "@/assets/css/tailwind-init.css";
import "@/assets/css/globals.css";

new Vue({
  ...vueOpts,
  render: h => h(App),
}).$mount("#app");
